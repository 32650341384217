module.exports = Object.freeze(require('rc')('eagle-region-tool', {
  client: {
    api: {
      address: 'https://infoanslag-stg.kalejdo.tv'
    },
    url: 'https://infoanslag-stg.kalejdo.tv'
  },
  db: {
    host: 'localhost',
    user: 'carge',
    password: 'carge',
    database: 'eagleinfo',
    port: 3306
  },
  server: {
    express: {
      port: 4000
    },
    jwt: {
      secret: 'asdf'
    },
    imageServer: 'http://localhost:4454'
  },
  validImageExtensions: ['.jpg', '.jpeg']
}))
